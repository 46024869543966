import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
//import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import NextServerActionsBook from "../../../static/images/commonImg/nextjs-server-actions.svg"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
import RegisterNewsLetterBody from "../../components/registerNewsLetter"
import ReactMasterVideo from "../../../static/images/commonImg/react-master-video.svg"  
import TSmasterVideo from "../../../static/images/commonImg/typescript-master-video.svg"

const generalData = {
    title: "はじめてさわるJavaScript",
    updated: "2024/3/15",
    urlToApp: "https://javascript-book-weather-app.netlify.app",
    urlToCode: "https://monotein-books.netlify.app/files/js-weather-book",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0C9C9SVFG",
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2025年3月リリース", title: "React全部入り：Reactマスター", img: ReactMasterVideo, url: "https://monotein.base.ec/items/101764819", description: "Reactを『なんとなく』は使える人が、7時間で自信を持ってReactを書けるようになる動画コース！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、発展的テクニック、そして最新のReactバージョン19まで全範囲をカバーした待望のマスターコース。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになるコース。<br/><br/>【動画数92本／計7時間26分】"},
    {oldPrice: "", newPrice: "", newRelease: "*2025年3月リリース", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterVideo, url: "https://monotein.base.ec/items/101844836", description: "2日でTypeScript + Reactの苦手意識を捨てされる動画コース。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用コース。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。<br/><br/>【動画数49本／計4時間37分】"},
    {title: "はじめてつくるReactアプリ", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: "「JavaScriptやjQueryの勉強をはじめたけど、つまってしまってその先にいけない」<br/><br/>「HTMLとCSSの勉強はすんだから、新しいテクノロジーを学んでみたい」<br/><br/>という人向けのReact入門書です。本書と同じ天気アプリをReactで開発します。"},
    {title: "はじめてつくるVueアプリ", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: "「Reactの次はVueに挑戦したい」<br/><br/>という人向けのVue開発入門書です。<br/><br/>本書と同じ天気アプリを最新のバージョンのVueを使って開発します。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", description: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", description: ""},
    {newRelease: "", title: "", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    [
        {name: "JSON Formatter", url: "https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa"},
        {name: "VS Code", url: "https://code.visualstudio.com/download"},
        {name: "Live Server", url: "https://marketplace.visualstudio.com/items?itemName=ritwickdey.LiveServer"},
        {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
    ],
    [
        {name: "画像", url: "https://monotein-books.netlify.app/files/js-weather-book"},
        {name: "CSSファイル", url: "https://monotein-books.netlify.app/js-weather-book-css"},
        {name: "weatherapi", url: "https://www.weatherapi.com"},
    ],
    [
        {name: "Netlify", url: "https://www.netlify.com"},
    ],
    [
        {name: "第4章完了時のindex.htmlファイル", url: "https://monotein-books.netlify.app/js-weather-book-html"},
        {name: "第4章完了時のmain.jsファイル", url: "https://monotein-books.netlify.app/js-weather-book-js"},
        {name: "アマゾンレビューURL", url: generalData.reviewLink1},
    ]
]

     /*    {name: "", url: ""}  */

const JsBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true}/>
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
{/*         <BookHeader/> */}
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            <br/>
            <li><span style={{color: "red"}}>*本書の動画版を、Udemyで無料で公開しています。本書の理解をさらに深めるために動画をご利用ください</span>：<a rel="noopener noreferrer" target="_blank" href="https://www.udemy.com/course/aki-miyoshi-js-beginner">本書動画版ページ（無料）</a></li>
            <br/>
            <li><span style={{color: "red"}}>*weatherAPIのサイトが利用できない場合について（2024年8月12日）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/jsbook-alternative-api">解説ページへ移動する</a></li>
            <br/>
            <li>アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode}>{generalData.urlToCode}</a></li>
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.ec") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>第{index + 1}章</h3>
                    {x.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="JS-Book"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default JsBookLinks
